@tailwind base;
@tailwind components;
@tailwind utilities;

.Mui-focusVisible {
  outline: 2px solid var(--hlx-color-border-focus);
  outline-offset: 2px;
}

.MuiMenuItem-root.Mui-focusVisible {
  outline: none;
}

a:where(:not(.hlx-link, .hlx-link-button)):focus-visible {
  outline: 2px solid var(--hlx-color-border-focus);
  outline-offset: 2px;
}
